<template>
  <div class="payment-container">
    <div class="header">
      {{ $t('inspectionInformation') }}
    </div>

    <div class="card-box center">
      <img width="25%" :src="require('@/assets/imgs/expired.png')">
      <div class="space title">{{ $t('expireTitle') }}</div>
      <div class="space">{{ $t('expireDesc') }}</div>
    </div>

    <div class="card-box">
      <div class="order-info">
        <div>{{ $t('orderNo') }}</div>
        <div>
          <span>{{ orderNo }}</span>
          <FuCopy :value="orderNo" />
        </div>
      </div>

      <FuCellGroup
        :title="$t('policyInformation')"
        :columns="expiryDetailsColumns"
        :datas="policyDetailsInfo"
        :position-botton:="false"
      />
    </div>
  </div>
</template>

<script>

import { getExpiryDetailsColumns } from './config/index'
import FuCellGroup from '@/components/FuCellGroup'
import FuCopy from '@/components/FuCopy'
import dao from '../../utils/dao'

export default {
  name: 'Payment',
  components: {
    FuCellGroup,
    FuCopy
  },
  data() {
    return {
      loading: false,
      popupVisible: false,
      expiryDetailsColumns: getExpiryDetailsColumns(this.lang),
      policyDetailsInfo: {},
      inspectionInfo: {}
    }
  },
  computed: {
    orderNo() {
      return this.inspectionInfo?.shareInspectionInfo?.orderNo ?? ''
    }
  },
  activated() {
    this.getInspectionInfo()
  },
  methods: {
    changeLang(val) {
      this.lang = val.split('-')[0]
    },
    lang() {
      return this.$store.state.base.lang || 'en'
    },
    async getInspectionInfo() {
      const data = dao.get('inspectionInfo')

      if (data) {
        this.policyDetailsInfo = data.shareInspectionInfo.shareInspectionPolicyInfo
        this.inspectionInfo = data
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
  .payment-container {
    background-color: #fbfbfb;

    .hint {
      font-size: 16px;
      margin: 10px 0px 5px 0px;
    }

  .header {
    padding: 0 20px;
    height: 50px;
    line-height: 45px;
    background-color: #D90109;
    font-size: 0.4rem;
    text-align: center;
    color: white;
  }

  .center {
      padding: 30px 15px !important;
      justify-content: center;
      align-items: center;
    }

  .card-box {
    font-size: 15px;
    margin: 10px;
    padding: 12px;
    box-shadow: 0px 0.4px 1.6px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;

    .space {
      margin-top: 20px;
    }

    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .order-info {
      font-size: 17px;
      background-color: #f2f2f2;
      border-radius: 12px;
      padding: 10px 13px;
      width: 100%;
      margin-bottom: 20px;

      span {
        font-weight: bold
      }
    }
  }

  .van-button{
      margin-top: 300px;
      position: fixed;
      left: 15%;
      background: #C82A21;
      box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      height: 47px;
      line-height: 47px;
      text-align: center;
      overflow: hidden;
      width: 70%;
      .van-button__text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
      }
    }

  .logo {
    text-align: center;
  }

  }

  </style>

